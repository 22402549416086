import AuthBase from "../AuthBase";
import {
    Autocomplete, Box,
    Button,
    FormControl,
    FormControlLabel,
    Grid, IconButton, MenuItem,
    Radio,
    RadioGroup, Select,
    Stack, Switch,
    TextField,
    useTheme
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useContext, useEffect, useRef, useState} from "react";
import useCallDataApi from "../../hooks/data";
import businessTemplate from '../website/BusinessTemplate';
import userTemplate from "../colleague/UserTemplate";
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import Loading from "../../components/Loading";
import DinoDialog from "../../components/Dialog";
import AuthContext from "../../context/AuthContext";
import SmsRoundedIcon from '@mui/icons-material/SmsRounded';
import AddCardRoundedIcon from '@mui/icons-material/AddCardRounded';
import {getFormData} from "../../utils/FormUtils";
import ConfigContext from "../../context/ConfigContext";
import thousandSeparator from "../../utils/numberUtils";
import countries from "../../utils/countries";
import {BillingProvider} from "../../context/BillingContext";
import AddBillingButton from "./billing/AddBillingButton";
import BillingServices from "./billing/BillingServices";
import AuthConfigContext from "../../context/AuthConfigContext";
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import {useSnackbar} from "notistack";
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import {truncateText} from "../../utils/misc";
import CustomTextField from "../../components/formValidation/CustomTextField";
import ApiKeyData from "./api-key/ApiKeyData";


const Settings = () => {
    const theme = useTheme()
    const [me, setMe] = useState(userTemplate)
    const [editedMe, setEditedMe] = useState(userTemplate)
    const [business, setBusiness] = useState(businessTemplate)
    const [editedBusiness, setEditedBusiness] = useState(businessTemplate)
    const [cardInfos, setCardInfos] = useState([])
    const [apiKeys, setApiKeys] = useState([])
    const [price, setPrice] = useState({one_price: '-', multiple_price: '-', small_sms_price: 0, medium_sms_price: 0, big_sms_price: 0,})
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
    const [deleteCardDialogOpen, setDeleteCardDialogOpen] = useState(false)
    const [newApiDialogOpen, setNewApiDialogOpen] = useState(false)
    const [deleteApiDialogOpen, setDeleteApiDialogOpen] = useState(false)
    const [deleteSubscriptionDialogOpen, setDeleteSubscriptionDialogOpen] = useState(false)
    const apiKeyForm = useRef(null)


    const [loading, setLoading] = useState(true)
    const [current, setCurrent] = useState(1)
    const [tokens, setTokens] = useState({business: -1, number: 0})
    const [bye, setBye] = useState('')
    const {getData: fetchMe, updateData: updateMe, postData} = useCallDataApi('colleague')
    const {getData: fetchBusiness, updateData: updateBusiness, postData: postBusiness} = useCallDataApi('business')
    const {getData: fetchCardInfo, postData: updateCardInfo} = useCallDataApi('card-info')
    const {getData: fetchSmsTokens, postData: postTokens} = useCallDataApi('sms-token')
    const {getData: fetchSettings} = useCallDataApi('general')
    const {getData: fetchApiKeys, postData: postApiKeys, deleteData: deleteApiKey} = useCallDataApi('api-keys')
    const {postData: genericPost} = useCallDataApi('')
    const {logoutUser} = useContext(AuthContext)
    const {ownUrl, baseUrl} = useContext(ConfigContext)
    const {billingServices, setBillingServices} = useContext(AuthConfigContext)
    const passwordResetForm = useRef(null)
    const {enqueueSnackbar} = useSnackbar()


    useEffect(() => {
        const getData = async () => {
            const [u, b, c, p, t, a] = await Promise.all([
                fetchMe('get_user'),
                fetchBusiness('get_business'),
                fetchCardInfo('get_for_business'),
                fetchSettings('get_price'),
                fetchSmsTokens('get_for_business'),
                fetchApiKeys()
            ])
            if (u) setMe(u)
            if (u) setEditedMe(u)
            if (b) setBusiness(b)
            if (b) setEditedBusiness(b)
            if (c) setCardInfos(c)
            if (p) setPrice(p)
            if (t) setTokens(t)
            if (a) setApiKeys(a)
        }
        getData()
            .catch(r => console.log(r))
            .finally(() => setLoading(false))
    }, [])

    const editBusiness = (diffList) => {
        setLoading(true)
        const data = {}
        for (let diff of diffList) {
            data[diff] = editedBusiness[diff]
        }
        updateBusiness(`${business?.id}/`, data)
            .then(b => {
                if (b) {
                    setBusiness(b)
                    setEditedBusiness(b)
                }
            })
            .finally(() => setLoading(false))
    }

    const editMe = (diffList) => {
        setLoading(true)
        const data = {}
        for (let diff of diffList) {
            data[diff] = editedMe[diff]
        }
        updateMe(`${me?.id}/`, data)
            .then(b => setMe(b))
            .finally(() => {
                setLoading(false)
                // TODO This might not be a good idea :S
                if (diffList.includes('email')) logoutUser()
            })
    }

    const isDifferentData = (original, edited, diffList) => {
        for (let diff of diffList) {
            if (original[diff] !== edited[diff]) {
                return true
            }
        }
        return false
    }

    const modifySubscription = (type) => {
        updateBusiness(`${business?.id}/`, {next_months_plan: type}).then(r => {
            setEditedBusiness(r)
            setBusiness(r)
        })
    }

    const changePassword = () => {
        const data = getFormData(passwordResetForm.current)
        postData('change_password/', data)
            .then(() => logoutUser())
            .catch(() => console.log('wrong password'))
    }

    const deleteAccount = () => {
        postData('delete_user/', {})
            .then(r => logoutUser())
            .catch(() => alert('Valami nem sikerült!'))
    }

    const changeActiveCard = (c) => {
        setLoading(true)
        updateCardInfo('change_active_card/', {card_id: c})
            .then(r => setCardInfos(r))
            .catch(() => {
                setLoading(false)
                alert('Valami nem sikerült!')
            })
            .finally(() => setLoading(false))
    }

    const deleteCard = (c) => {
        setLoading(true)
        updateCardInfo('delete_card/', {card_id: c})
            .then(r => setCardInfos(r))
            .catch(() => {
                setLoading(false)
                alert('Valami nem sikerült!')
            })
            .finally(() => setLoading(false))
    }

    const deleteApi = (c) => {
        setLoading(true)
        deleteApiKey(c)
            .then(r => setApiKeys([...apiKeys.filter(a => a?.id !== c)]))
            .catch(() => {
                setLoading(false)
                alert('Valami nem sikerült!')
            })
            .finally(() => setLoading(false))
    }

    const addCard = () => {
        setLoading(true)
        genericPost('register_card/', {redirect_url: `${ownUrl}/payment-over`})
            .then(r => window.location.href = r.paymentLink)
            .catch(() => {
                setLoading(false)
                alert('Sikertelen művelet!')
            })
    }

    const copyCalendarLink = () => {
        setLoading(true)
        fetchBusiness('get_calendar_slug/').then(r => {
            navigator.clipboard.writeText(baseUrl + '/business-cal/' + r?.slug)
                .then(() => enqueueSnackbar('Kimásoltad a linket!', {variant: 'success'}))
                .catch(() => enqueueSnackbar('Nem sikerült kimásolni a linket!', {variant: 'error'}))
        }).finally(() => setLoading(false))
    }

    const buySmsTokens = (size) => {
        setLoading(true)
        postTokens('buy_tokens/', {size: size})
            .then(response => window.location.href = response?.redirect)
            .catch(e => console.log(e, 'valami nem jó'))
            .finally(() => setLoading(false))
    }

    const foreverDeleteActions = <>
        <Button variant='outlined' style={{padding: 15}} onClick={() => setDeleteDialogOpen(false)}>mégsem</Button>
        <Button variant='contained' style={{padding: 15}} disabled={bye.toUpperCase() !== 'TÖRLÉS'} onClick={() => {
            setDeleteDialogOpen(false)
            deleteAccount()
        }} endIcon={<DeleteForeverRoundedIcon/>}>Fiók törlése</Button>
    </>

    const cardDeleteActions = <>
        <Button variant='outlined' style={{padding: 15}} onClick={() => setDeleteCardDialogOpen(false)}>mégsem</Button>
        <Button variant='contained' style={{padding: 15}} onClick={() => {
            setDeleteCardDialogOpen(false)
            deleteCard(current)
        }} endIcon={<DeleteForeverRoundedIcon/>}>Kártya törlése</Button>
    </>

    const deleteApiActions = <>
        <Button variant='outlined' style={{padding: 15}} onClick={() => setDeleteApiDialogOpen(false)}>mégsem</Button>
        <Button variant='contained' style={{padding: 15}} onClick={() => {
            setDeleteApiDialogOpen(false)
            deleteApi(current)
        }} endIcon={<DeleteForeverRoundedIcon/>}>Kulcs törlése</Button>
    </>

    const subscriptionDeleteActions = <>
        <Button variant='outlined' style={{padding: 15}} onClick={() => setDeleteSubscriptionDialogOpen(false)}>mégsem</Button>
        <Button variant='contained' style={{padding: 15}} onClick={() => {
            setDeleteSubscriptionDialogOpen(false)
            modifySubscription('none')
        }} endIcon={<DeleteForeverRoundedIcon/>}>Előfizetés lemondása</Button>
    </>

    const subscriptionMap = {
        'none': 'Lemondva',
        'one': 'Egyszemélyes csomag',
        'multiple': 'Többszemélyes csomag'
    }

    return <AuthBase label='Beállítások'>
        <div className="main">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <div className="white-card">
                        <Typography variant='subtitle1'>Saját adatok módosítása</Typography>
                        <Typography variant='body2' color='#536B74'>Itt tudod módosítani az adminiszrátori felhasználó adatait. Ezen adatokat a Kollégák résznél is módosíthatod.</Typography>
                        <Stack spacing={1} marginTop={2} marginBottom={2}>
                            <Typography variant='caption' color={theme.palette.primary[500]}>Vezetéknév</Typography>
                            <TextField variant='standard' value={editedMe?.last_name} onChange={(event) => {
                                setEditedMe({...editedMe, last_name: event.target.value})
                            }}/>
                            <Typography variant='caption' color={theme.palette.primary[500]}>Keresztnév</Typography>
                            <TextField variant='standard' value={editedMe?.first_name} onChange={(event) => {
                                setEditedMe({...editedMe, first_name: event.target.value})
                            }}/>
                            <Typography variant='caption' color={theme.palette.primary[500]}>titulus</Typography>
                            <TextField variant='standard' value={editedMe?.title} onChange={(event) => {
                                setEditedMe({...editedMe, title: event.target.value})
                            }}/>
                        </Stack>
                        <Button endIcon={<SaveRoundedIcon/>}
                                onClick={() => editMe(['last_name', 'first_name', 'title'])} variant='contained'
                                sx={{color: '#fff', padding: '15px 12px 15px 16px'}}
                                disabled={!isDifferentData(me, editedMe, ['last_name', 'first_name', 'title'])}>Módosítások
                            mentése</Button>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className="white-card">
                        <Typography variant='subtitle1'>Számlázási és SimplePay adattovábbítási adatok módosítása</Typography>
                        <Typography variant='body2' color='#536B74'>Az adataid módosításához vedd fel velünk a kapcsolatot az admin@dinobooking.hu e-mail címen.</Typography>
                        {/*<Typography variant='body2' color='#536B74'>Itt van lehetőséged módosítani a számlázási adataidat. Ezen adatok alapján kerül kiállításra a havi számla.</Typography>*/}
                        <FormControl>
                            <RadioGroup
                                name="controlled-radio-buttons-group"
                                value={editedBusiness?.incorporation_type}
                                onChange={(event => {
                                    setEditedBusiness({...editedBusiness, incorporation_type: event.target.value})
                                })}
                                sx={{my: 1}}
                            >
                                <FormControlLabel disabled value="company" control={<Radio/>} label={<Typography color='#6F8E9A'
                                                                                                        variant='caption'>Vállalkozás</Typography>}/>
                                <FormControlLabel disabled value="taxable_person" control={<Radio/>}
                                                  label={<Typography color='#6F8E9A'
                                                                     variant='caption'>Magánszemély</Typography>}/>
                            </RadioGroup>
                        </FormControl>
                        {editedBusiness?.incorporation_type === 'company' ?
                            <Stack spacing={1} marginTop={2} marginBottom={2}>
                                <Typography variant='caption' color={theme.palette.primary[500]}>Vállalkozás
                                    neve</Typography>
                                <TextField disabled variant='standard' value={editedBusiness?.name} onChange={(event) => {
                                    setEditedBusiness({...editedBusiness, name: event.target.value})
                                }}/>
                                <Typography variant='caption' color={theme.palette.primary[500]}>Adószám</Typography>
                                <TextField disabled variant='standard' value={editedBusiness?.vat_number} onChange={(event) => {
                                    setEditedBusiness({...editedBusiness, vat_number: event.target.value})
                                }}/>
                                <Typography variant='caption' color={theme.palette.primary[500]}>Ország</Typography>
                                <Autocomplete
                                    id="country-select-demo"
                                    sx={{width: '100%'}}
                                    options={countries}
                                    disabled
                                    value={editedBusiness?.country}
                                    onChange={(event, newValue) => {
                                        setEditedBusiness({...editedBusiness, country: newValue});
                                    }}
                                    isOptionEqualToValue={(option, value) => option.code === value.code}
                                    autoHighlight
                                    getOptionLabel={(option) => option.label}
                                    renderOption={(props, option) => (
                                        <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                            <img
                                                loading="lazy"
                                                width="20"
                                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                alt=""
                                            />
                                            {option.label} ({option.code})
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant='standard'
                                            fullWidth
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password', // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                />
                                <Typography variant='caption'
                                            color={theme.palette.primary[500]}>Irányítószám</Typography>
                                <TextField disabled variant='standard' value={editedBusiness?.zip} onChange={(event) => {
                                    setEditedBusiness({...editedBusiness, zip: event.target.value})
                                }}/>
                                <Typography variant='caption' color={theme.palette.primary[500]}>Megye</Typography>
                                <TextField disabled variant='standard' value={editedBusiness?.state} onChange={(event) => {
                                    setEditedBusiness({...editedBusiness, state: event.target.value})
                                }}/>
                                <Typography variant='caption' color={theme.palette.primary[500]}>Város</Typography>
                                <TextField disabled variant='standard' value={editedBusiness?.city} onChange={(event) => {
                                    setEditedBusiness({...editedBusiness, city: event.target.value})
                                }}/>
                                <Typography variant='caption' color={theme.palette.primary[500]}>Cím</Typography>
                                <TextField disabled variant='standard' value={editedBusiness?.address} onChange={(event) => {
                                    setEditedBusiness({...editedBusiness, address: event.target.value})
                                }}/>
                            </Stack>
                            :
                            <Stack spacing={1} marginTop={2} marginBottom={2}>
                                <Typography variant='caption' color={theme.palette.primary[500]}>Név</Typography>
                                <TextField disabled variant='standard' value={editedBusiness?.name} onChange={(event) => {
                                    setEditedBusiness({...editedBusiness, name: event.target.value})
                                }}/>
                                <Typography variant='caption' color={theme.palette.primary[500]}>Ország</Typography>
                                <Autocomplete
                                    id="country-select-demo"
                                    sx={{width: '100%'}}
                                    options={countries}
                                    disabled
                                    autoHighlight
                                    value={editedBusiness?.country}
                                    onChange={(event, newValue) => {
                                        setEditedBusiness({...editedBusiness, country: newValue});
                                    }}
                                    isOptionEqualToValue={(option, value) => option.code === value.code}
                                    getOptionLabel={(option) => option.label}
                                    renderOption={(props, option) => (
                                        <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                            <img
                                                loading="lazy"
                                                width="20"
                                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                alt=""
                                            />
                                            {option.label} ({option.code})
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant='standard'
                                            fullWidth
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password', // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                />
                                <Typography variant='caption'
                                            color={theme.palette.primary[500]}>Irányítószám</Typography>
                                <TextField disabled variant='standard' value={editedBusiness?.zip} onChange={(event) => {
                                    setEditedBusiness({...editedBusiness, zip: event.target.value})
                                }}/>
                                <Typography variant='caption' color={theme.palette.primary[500]}>Megye</Typography>
                                <TextField disabled variant='standard' value={editedBusiness?.state} onChange={(event) => {
                                    setEditedBusiness({...editedBusiness, state: event.target.value})
                                }}/>
                                <Typography variant='caption' color={theme.palette.primary[500]}>Város</Typography>
                                <TextField disabled variant='standard' value={editedBusiness?.city} onChange={(event) => {
                                    setEditedBusiness({...editedBusiness, city: event.target.value})
                                }}/>
                                <Typography variant='caption' color={theme.palette.primary[500]}>Cím</Typography>
                                <TextField disabled variant='standard' value={editedBusiness?.address} onChange={(event) => {
                                    setEditedBusiness({...editedBusiness, address: event.target.value})
                                }}/>
                            </Stack>
                        }
                        {/*<Button*/}
                        {/*    onClick={() => editBusiness(['incorporation_type', 'name', 'vat_number', 'state', 'zip', 'city', 'address', 'country'])}*/}
                        {/*    variant='contained' sx={{color: '#fff', padding: '15px 12px 15px 16px'}}*/}
                        {/*    endIcon={<SaveRoundedIcon/>}*/}
                        {/*    disabled={!isDifferentData(business, editedBusiness, ['incorporation_type', 'name', 'vat_number', 'state', 'zip', 'city', 'address', 'country'])}>Módosítások*/}
                        {/*    mentése</Button>*/}
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className="white-card">
                        <Typography variant='subtitle1'>Jelszó módosítása</Typography>
                        <Typography variant='body2' color='#536B74'>Kérjük változtasd meg rendszeresen a jelszavadat és ne add meg senkinek!</Typography>
                        <form ref={passwordResetForm}>
                            <Stack spacing={1} marginTop={2} marginBottom={2}>
                                <Typography variant='caption' color={theme.palette.primary[500]}>Régi
                                    jelszó</Typography>
                                <TextField variant='standard' type='password' name='old_password'/>
                                <Typography variant='caption' color={theme.palette.primary[500]}>Új jelszó</Typography>
                                <TextField variant='standard' type='password' name='new_password'/>
                                <Typography variant='caption' color={theme.palette.primary[500]}>Új jelszó
                                    mégegyszer</Typography>
                                <TextField variant='standard' type='password' name='new_again'/>
                            </Stack>
                        </form>
                        <Button onClick={changePassword} variant='contained'
                                sx={{color: '#fff', padding: '15px 12px 15px 16px'}}>Jelszó módosítása</Button>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className="white-card">
                        <Typography variant='subtitle1'>Számlázási beállítások</Typography>
                        <Typography variant='body2' color='#536B74'>Válassz az integrált számlázási lehetőségek közül az automata számlázáshoz.</Typography>
                        <BillingProvider billingServices={billingServices} setBillingServices={setBillingServices}>
                            <BillingServices billingServices={billingServices}/>
                            <AddBillingButton/>
                        </BillingProvider>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className="white-card">
                        <Typography variant='subtitle1'>Fizetési adatok módosítása</Typography>
                        <Typography variant='body2' color='#536B74'>Az OTP SimplePay szolgáltatóval kötött szerződést követően másold be az azonosítókat.</Typography>

                        <FormControlLabel control={<Switch checked={editedBusiness?.payment_on}
                                                           onChange={() => setEditedBusiness({
                                                               ...editedBusiness,
                                                               payment_on: !editedBusiness?.payment_on
                                                           })} name="payment_on"/>}
                                          disabled={editedBusiness.payment_credentials.merchant_id === "" || editedBusiness.payment_credentials.secret_key === ""}
                                          label={<Typography color='#1C2427' variant='caption'>Fizetés bekapcsolása
                                              kliensek számára</Typography>}/>

                        <Grid container spacing={2} marginTop={2} marginBottom={2}>
                            <Grid item xs={12} md={6}>
                                <Stack spacing={1}>
                                    <Typography variant='caption' color={theme.palette.primary[500]}>Fizetés típus
                                        kiválasztása</Typography>
                                    <FormControl fullWidth>
                                        <Select
                                            id="payment-select"
                                            value={editedBusiness?.payment_type || 'simple'}
                                            variant='standard'
                                            label="Fizetési típus"
                                            onChange={(event) => setEditedBusiness({
                                                ...editedBusiness,
                                                payment_type: event.target.value
                                            })}
                                        >
                                            <MenuItem value='simple'>SimplePay</MenuItem>
                                            {/*<MenuItem value='stripe'>Stripe</MenuItem>*/}
                                        </Select>
                                    </FormControl>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Stack spacing={1}>
                                    <Typography variant='caption'
                                                color={theme.palette.primary[500]}>devizanem</Typography>
                                    <FormControl fullWidth>
                                        <Select
                                            id="currency-select"
                                            value={editedBusiness?.currency || 'HUF'}
                                            variant='standard'
                                            label="Fizetési típus"
                                            onChange={(event) => setEditedBusiness({
                                                ...editedBusiness,
                                                currency: event.target.value
                                            })}
                                        >
                                            <MenuItem value='HUF'>HUF</MenuItem>
                                            {/*<MenuItem value='eur'>EUR</MenuItem>*/}
                                        </Select>
                                    </FormControl>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Stack spacing={1}>
                                    <Typography variant='caption' color={theme.palette.primary[500]}>Kereskedői
                                        azonosító</Typography>
                                    <TextField variant='standard' value={editedBusiness.payment_credentials.merchant_id}
                                               onChange={(event) => setEditedBusiness({
                                                   ...editedBusiness,
                                                   payment_credentials: {
                                                       ...editedBusiness.payment_credentials,
                                                       merchant_id: event.target.value
                                                   }
                                               })}/>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Stack spacing={1}>
                                    <Typography variant='caption' color={theme.palette.primary[500]}>Titkosító
                                        kulcs</Typography>
                                    <TextField variant='standard' type='password'
                                               value={editedBusiness.payment_credentials.secret_key}
                                               onChange={(event) => setEditedBusiness({
                                                   ...editedBusiness,
                                                   payment_credentials: {
                                                       ...editedBusiness.payment_credentials,
                                                       secret_key: event.target.value
                                                   }
                                               })}/>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Button
                            onClick={() => editBusiness(['payment_on', 'payment_type', 'currency', 'payment_credentials',])}
                            disabled={!isDifferentData(business, editedBusiness, ['payment_on', 'payment_type', 'currency', 'payment_credentials'])}
                            variant='contained' sx={{color: '#fff', padding: '15px 12px 15px 16px'}}
                            endIcon={<SaveRoundedIcon/>}>Módosítások mentése</Button>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className="white-card">
                        <Typography variant='subtitle2'>SMS Tokenek</Typography>
                        <Typography variant='body2' color='#536B74' gutterBottom>Vásárolj sms tokeneket, egy token nagyjából egy smsnek felel meg. Olvass róla bővebben <a
                            href="https://dinobooking.hu/sms-tokenek" target='_blank' rel="noreferrer">ide kattintva</a></Typography>
                        <Typography variant='body2' color='#536B74' gutterBottom>Tokenek száma: {tokens?.number} db</Typography>

                        <Grid container spacing={1} marginBottom={2} marginTop={2}>
                            <Grid item xs={12}>
                                <Stack direction='row' spacing={2} sx={{
                                    border: '1px solid',
                                    borderColor: theme.palette.primary[50],
                                    borderRadius: '16px',
                                    padding: '16px 24px'
                                }}>
                                    <SmsRoundedIcon
                                        sx={{color: theme.palette.primary[500], fontSize: '50px'}}/>
                                    <Stack spacing={1}>
                                        <Typography variant='subtitle1' color={theme.palette.primary[600]}>Kicsi SMS csomag</Typography>
                                        <Typography variant='body2' color='#38474D'>100 db token</Typography>
                                        <Typography variant='subtitle2' color={theme.palette.primary[700]}>{thousandSeparator(price.small_sms_price)} Ft ({price.small_sms_price / 100} Ft/db)</Typography>
                                        <Button onClick={() => buySmsTokens('small')} variant='contained' sx={{
                                            padding: '15px 12px 15px 16px',
                                            width: 'fit-content'
                                        }}>Vásárlás</Button>
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction='row' spacing={2} sx={{
                                    border: '1px solid',
                                    borderColor: theme.palette.primary[50],
                                    borderRadius: '16px',
                                    padding: '16px 24px'
                                }}>
                                    <SmsRoundedIcon
                                        sx={{color: theme.palette.primary[500], fontSize: '50px'}}/>
                                    <Stack spacing={1}>
                                        <Typography variant='subtitle1' color={theme.palette.primary[600]}>Közepes SMS csomag</Typography>
                                        <Typography variant='body2' color='#38474D'>500 db token</Typography>
                                        <Typography variant='subtitle2' color={theme.palette.primary[700]}>{thousandSeparator(price.medium_sms_price)} Ft ({price.medium_sms_price / 500} Ft/db)</Typography>
                                        <Button onClick={() => buySmsTokens('medium')} variant='contained' sx={{
                                            padding: '15px 12px 15px 16px',
                                            width: 'fit-content'
                                        }}>Vásárlás</Button>
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction='row' spacing={2} sx={{
                                    border: '1px solid',
                                    borderColor: theme.palette.primary[50],
                                    borderRadius: '16px',
                                    padding: '16px 24px'
                                }}>
                                    <SmsRoundedIcon
                                        sx={{color: theme.palette.primary[500], fontSize: '50px'}}/>
                                    <Stack spacing={1}>
                                        <Typography variant='subtitle1' color={theme.palette.primary[600]}>Nagy SMS csomag</Typography>
                                        <Typography variant='body2' color='#38474D'>1000 db token</Typography>
                                        <Typography variant='subtitle2' color={theme.palette.primary[700]}>{thousandSeparator(price.big_sms_price)} Ft ({price.big_sms_price / 1000} Ft/db)</Typography>
                                        <Button onClick={() => buySmsTokens('big')} variant='contained' sx={{
                                            padding: '15px 12px 15px 16px',
                                            width: 'fit-content'
                                        }}>Vásárlás</Button>
                                    </Stack>
                                </Stack>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className="white-card">
                        <Typography variant='subtitle2'>Előfizetési csomag</Typography>
                        <Typography variant='body2' color='#536B74' gutterBottom>Előfizetési csomag váltása a havi fordulóponttól lehetséges. (Következő: {business.next_payment})</Typography>
                        {business.next_months_plan !== business.subscription && <Typography variant='body2' color='#536B74' gutterBottom>Következő hónaptól: {subscriptionMap[business.next_months_plan]}</Typography>}
                        <Grid container spacing={1} marginBottom={2} marginTop={2}>
                            <Grid item xs={12}>
                                <Stack direction='row' spacing={2} sx={{
                                    border: '1px solid',
                                    borderColor: theme.palette.primary[50],
                                    borderRadius: '16px',
                                    padding: '16px 24px'
                                }}>
                                    <AccountCircleRoundedIcon
                                        sx={{color: theme.palette.primary[500], fontSize: '50px'}}/>
                                    <Stack spacing={1}>
                                        <Typography variant='subtitle1' color={theme.palette.primary[600]}>Egyszemélyes
                                            vállalkozói csomag</Typography>
                                        <Typography variant='body2' color='#38474D'>Ideális egy személyes
                                            vállalkozásoknak ahol egy szolgáltató végzi a munkát.</Typography>
                                        <Typography variant='subtitle2' color={theme.palette.primary[700]}>{thousandSeparator(price.one_price)} Ft/hó</Typography>
                                        <Button onClick={() => modifySubscription('one')}
                                                disabled={business?.next_months_plan === 'one'} variant='contained' sx={{
                                            padding: '15px 12px 15px 16px',
                                            width: 'fit-content'
                                        }}>{business?.next_months_plan === 'one' ? 'Előfizetési csomag aktív' : 'Előfizetési csomagot váltok'}</Button>
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction='row' spacing={2} sx={{
                                    border: '1px solid',
                                    borderColor: theme.palette.primary[50],
                                    borderRadius: '16px',
                                    padding: '16px 24px'
                                }}>
                                    <PeopleRoundedIcon sx={{color: theme.palette.primary[500], fontSize: '50px'}}/>
                                    <Stack spacing={1}>
                                        <Typography variant='subtitle1' color={theme.palette.primary[600]}>Többszemélyes
                                            vállalkozói csomag</Typography>
                                        <Typography variant='body2' color='#38474D'>Ideális több fős vállalkozásoknak
                                            ahol eltérő személyekhez lehet időpontot foglalni.</Typography>
                                        <Typography variant='subtitle2' color={theme.palette.primary[700]}>{thousandSeparator(price.multiple_price)} Ft/hó</Typography>
                                        <Button onClick={() => modifySubscription('multiple')}
                                                disabled={business?.next_months_plan === 'multiple'} variant='contained'
                                                sx={{
                                                    padding: '15px 12px 15px 16px',
                                                    width: 'fit-content'
                                                }}>{business?.next_months_plan === 'multiple' ? 'Előfizetési csomag aktív' : 'Előfizetési csomagot váltok'}</Button>
                                    </Stack>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Button onClick={() => setDeleteSubscriptionDialogOpen(true)}>Előfizetés lemondása</Button>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className="white-card">
                        <Typography variant='subtitle2'>Bankkártyák</Typography>
                        <Typography variant='body2' color='#536B74' gutterBottom>Új kártya hozzáadása esetén időbe telhet míg az adatok ellenőrízve lesznek, addig az xxxx-xxxx-xxxx-xxxx számú kártyát mutatja a rendszer.</Typography>
                        <Stack spacing={1} marginBottom={2}>
                            {cardInfos?.map((c, i) => <Box key={`card_${i}`} >
                                <Stack direction='row' spacing={2}>
                                    <Stack spacing={1} className='bank-card'>
                                        <Box style={{width: '30px', height: '20px', background: '#c79a00', borderRadius: '5px', border: '1px solid #866b00'}}/>
                                        <Typography variant='caption' color='#fff'>{c.card_mask}</Typography>
                                        <Typography variant='caption' color='#fff'>VALID: {c.expiry}</Typography>
                                    </Stack>
                                    <Stack spacing={1}>
                                        {c.active ?
                                            <Button disabled variant='contained'>Kiválasztva</Button>
                                        :
                                            <Button onClick={() => changeActiveCard(c.id)} variant='contained'>Kiválasztás</Button>
                                        }
                                        <Button onClick={() => {
                                            setCurrent(c.id)
                                            setDeleteCardDialogOpen(true)
                                        }} >Törlés</Button>
                                    </Stack>
                                </Stack>
                            </Box>)}
                        </Stack>
                        <Button variant='contained' endIcon={<AddCardRoundedIcon/>} onClick={addCard}>Kártya hozzáadása</Button>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className="white-card">
                        <Typography variant='subtitle2'>Naptár megosztása</Typography>
                        <Typography variant='body2' color='#536B74' gutterBottom>Ezzel a linkkel tudod beállítani a Google és egyéb naptáraidat.</Typography>
                        <Button variant='contained' onClick={() => copyCalendarLink()} endIcon={<ContentCopyRoundedIcon/>}>Link másolása</Button>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className="white-card">
                        <Typography variant='subtitle2'>API Kulcsok</Typography>
                        <Typography variant='body2' color='#536B74' gutterBottom>Az API kulcsokkal biztonságosan hozzáférhetsz a DinoBooking különféle funkcióihoz. Kezeld ezeket a kulcsokat bizalmasan a rendszer védelme érdekében.</Typography>
                        <Stack spacing={2} marginBottom={2}>
                            {apiKeys?.map(k => <Stack spacing={2} direction='row' key={k?.id} alignItems='center'>
                                <Typography variant='body2' color='#536B74' gutterBottom >{truncateText(k?.name, 50)}</Typography>
                                <Button variant='contained' onClick={() => {
                                    navigator.clipboard.writeText(k.key)
                                    enqueueSnackbar("Másolva", {variant: "success"})
                                }} endIcon={<ContentCopyRoundedIcon/>}>API kulcs másolása</Button>
                                <Button onClick={() => {
                                    setCurrent(k.id)
                                    setDeleteApiDialogOpen(true)
                                }} endIcon={<DeleteForeverRoundedIcon/>}>API kulcs törlése</Button>
                            </Stack>)}
                        </Stack>
                        <Button variant='contained' onClick={() => setNewApiDialogOpen(true)} endIcon={<AddCircleRoundedIcon/>}>Új API kulcs hozzáadása</Button>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className="white-card">
                        <Typography variant='subtitle2'>Fiók törlése</Typography>
                        <Typography variant='body2' color='#536B74' gutterBottom>Ha admin felhasználóként kitörli a
                            fiókot minden adat elvész.</Typography>
                        <Button onClick={() => setDeleteDialogOpen(true)} endIcon={<DeleteForeverRoundedIcon/>}>Fiók
                            törlése</Button>
                    </div>
                </Grid>
            </Grid>
        </div>

        <DinoDialog open={deleteDialogOpen}
                    handleClose={() => setDeleteDialogOpen(false)}
                    title='Fiók törlése'
                    subtitle='Ha admin felhasználóként törli a fiókot minden adat elvész!'
                    actions={foreverDeleteActions}>
            <Stack spacing={1}>
                <Typography variant='caption'>Ha biztosan törölni szeretné gépelje be a "törlés" szót</Typography>
                <TextField variant='standard' value={bye} onChange={e => setBye(e.target.value)}/>
            </Stack>
        </DinoDialog>

        <DinoDialog open={deleteCardDialogOpen}
                    handleClose={() => setDeleteCardDialogOpen(false)}
                    title='Kártya törlése'
                    subtitle='Biztosan törli ezt a kártyát?'
                    actions={cardDeleteActions}>
        </DinoDialog>

        <DinoDialog open={deleteApiDialogOpen}
                    handleClose={() => setDeleteApiDialogOpen(false)}
                    title='Api kulcs törlése'
                    subtitle='Biztosan törli ezt a kulcsot?'
                    actions={deleteApiActions}>
        </DinoDialog>

        <DinoDialog open={newApiDialogOpen}
                    handleClose={() => setDeleteApiDialogOpen(false)}
                    title='Új API kulcs'
                    subtitle='Add meg a nevet, érdemes azt az alkalmazás nevét megadni ahol használni fogod.'
                    actions={<>
                        <Button variant='outlined' style={{padding: 15}} onClick={() => setNewApiDialogOpen(false)}>mégsem</Button>
                        <Button variant='contained' style={{padding: 15}} onClick={() => {
                            const data = apiKeyForm.current.getData()
                            if (!data) return
                            if (Object.entries(data)?.length > 0) {
                                postApiKeys('', data).then(r => setApiKeys([...apiKeys, r]))
                                setNewApiDialogOpen(false)
                            }
                        }} endIcon={<AddCircleRoundedIcon/>}>Kulcs hozzáadása</Button>
                    </>}>
            <ApiKeyData reference={apiKeyForm}/>
        </DinoDialog>

        <DinoDialog open={deleteSubscriptionDialogOpen}
                    handleClose={() => setDeleteSubscriptionDialogOpen(false)}
                    title='Előfizetés lemondása'
                    subtitle='Biztosan lemondja az előfizetést? Az aktuális hónap végével nem fogja tudni használani a rendszert!'
                    actions={subscriptionDeleteActions}>
        </DinoDialog>

        <Loading isLoading={loading}/>
    </AuthBase>
}

export default Settings