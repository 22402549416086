import {Avatar, IconButton, Stack, Tooltip, Typography, useTheme} from "@mui/material";
import szamlazzLogo from "../../../assets/szamlazz.jpg"
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import {useContext} from "react";
import BillingContext from "../../../context/BillingContext";


const SzamlazzDisplay = ({id, name, active}) => {
    const {setBillingServiceActive, deactivateBillingService, setDeleteBillingDetail, setEditBillingDetail, setCurrent} = useContext(BillingContext)

    return <Stack direction='row' justifyContent='space-between' sx={{width: '100%'}}>
        <Stack direction='row' spacing={2} alignItems='center'>
            <Avatar src={szamlazzLogo} sx={{width: 70, height: 70, alignSelf: 'center'}}/>
            <div className="text-container">
                <Stack direction='row' spacing={1} justifyContent='center' alignItems='center'>
                    <Typography align="left" variant="subtitle1">{name}</Typography>
                    {active && <Tooltip title="Jelenleg aktív"><div className='active-dot'/></Tooltip>}
                </Stack>
                <Typography align="left" variant="body2">számlázz</Typography>
            </div>
        </Stack>
        <Stack direction='row' spacing={2}>
            <Stack justifyContent='center' alignItems='center'>
                <Tooltip title="Szerkesztés">
                    <IconButton onClick={() => {
                        setCurrent(id)
                        setEditBillingDetail(true)
                    }}><EditRoundedIcon/></IconButton>
                </Tooltip>
            </Stack>
            <Stack justifyContent='center' alignItems='center'>
                <Tooltip title="Törlés">
                    <IconButton onClick={() => {
                        setCurrent(id)
                        setDeleteBillingDetail(true)
                    }}><DeleteRoundedIcon/></IconButton>
                </Tooltip>
            </Stack>
            <Stack justifyContent='center' alignItems='center'>
                {active ? <Tooltip title="Deaktiválás"><IconButton onClick={() => deactivateBillingService(id)}><CancelRoundedIcon/></IconButton></Tooltip> : <Tooltip title="Legyen ez az aktív"><IconButton onClick={() => setBillingServiceActive(id)}><CheckCircleRoundedIcon/></IconButton></Tooltip>}
            </Stack>
        </Stack>
    </Stack>
}

export default SzamlazzDisplay