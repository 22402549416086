import {Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useState} from "react";
import {validateRequired} from "../../../components/formValidation/validators";
import ErrorHandlingForm from "../../../components/formValidation/ErrorHandlingForm";
import CustomTextField from "../../../components/formValidation/CustomTextField";

const BillingoDetails = ({reference, obj}) => {
    const [data, setData] = useState({
        name: '' || obj?.name,
        block_id: '' || obj?.block_id,
        bank_account_id: '' || obj?.bank_account_id,
        api_key: '' || obj?.api_key,
    })

    const [errors, setErrors] = useState({
        name: false,
        block_id: false,
        bank_account_id: false,
        api_key: false,
    })

    const validators = {
        name: [validateRequired],
        block_id: [validateRequired],
        bank_account_id: [validateRequired],
        api_key: [validateRequired],
    }

    return <ErrorHandlingForm reference={reference} data={data} setData={setData} errors={errors} setErrors={setErrors} validators={validators}>
        <Stack spacing={2} marginBottom={2} marginTop={2}>
            <Typography variant='caption' color='primary.main'>Név*</Typography>
            <CustomTextField
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                name='name'
                variant='standard'
            />
            <Typography variant='caption' color='primary.main'>Blokk id*</Typography>
            <CustomTextField
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                name='block_id'
                variant='standard'
            />
            <Typography variant='caption' color='primary.main'>Bank számla id*</Typography>
            <CustomTextField
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                name='bank_account_id'
                variant='standard'
            />
            <Typography variant='caption' color='primary.main'>API kulcs*</Typography>
            <CustomTextField
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                name='api_key'
                variant='standard'
            />
        </Stack>

    </ErrorHandlingForm>
}

export default BillingoDetails