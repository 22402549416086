import {createContext} from "react";
import {useEffect, useState} from "react";
import useCallDataApi from "../hooks/data";
import {useSnackbar} from "notistack";
import Loading from "../components/Loading";

const ReservationContext = createContext();

export default ReservationContext;

export const ReservationProvider = ({ reservations, setReservations, setNewReservationOpen, children }) => {
    const steps = ['category', 'service', 'price', 'date', 'fill']

    const snackbar = useSnackbar()
    const {postData} = useCallDataApi('admin-reservation')
    const {getData: fetchQuestions} = useCallDataApi('sp-service-question')
    const {getData: fetchExtraItems} = useCallDataApi('sp-extra-item')
    const [selected, setSelected] = useState({})
    const [questions, setQuestions] = useState([])
    const [extraItems, setExtraItems] = useState([])
    const [activeStep, setActiveStep] = useState(0)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState({})

    const [personalData, setPersonalData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '+36',
    })
    const [billingData, setBillingData] = useState({
        billing_name: '',
        billing_country: '',
        billing_postal_code: '',
        billing_city: '',
        billing_address: '',
        billing_vat_number: null,
    })
    const [paymentData, setPaymentData] = useState({
        payment: 'card',
    })
    const [questionData, setQuestionData] = useState({})
    const [otherData, setOtherData] = useState({
        comment: ''
    })
    const [extraItemData, setExtraItemData] = useState([])

    useEffect(() => {
        if (selected?.service) {
            setLoading(true)
            fetchQuestions(`get_for_service?service=${selected?.service?.id}`)
                .then(q => setQuestions(q))
                .finally(() => setLoading(false))
            fetchExtraItems(`get_for_service?service=${selected?.service?.id}`)
                .then(e => setExtraItems(e))
                .finally(() => setLoading(false))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected?.service])

    useEffect(() => {
        if (!selected?.price) setExtraItemData([])

    }, [selected?.price])

    const billingDetailsMandatory = () => selected?.service?.mandatory_billing_details === 'mandatory' || (paymentData.payment === 'simple' && selected?.service?.automatic_invoice)

    const isPersonalFilled = () => personalData?.first_name && personalData?.last_name && personalData?.email && personalData?.phone?.length > 3
    // const isBillingFilled = () => billingData?.billing_name && billingData?.billing_city && billingData?.billing_country && billingData?.billing_postal_code && billingData?.billing_address
    // const isPaymentFilled = () => paymentData?.payment
    // const questionsFilled = () => questions.filter(q => q.required && q.answer_type != 3).every(rq => questionData[`q_${rq?.id}`])
    //
    const isError = () => !error?.first_name && !error?.last_name && !error?.email && !error?.phone

    const canProceed = () => {
        return isPersonalFilled() && isError()
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [activeStep])

    const plusItem = (item) => {
        const existingItem = extraItemData.find(i => i.id === item.id)
        if (existingItem) {
            const updatedItems = extraItemData.map(i => {
                if (i.id === item.id) {
                    return { ...i, quantity: i.quantity + 1 }
                }
                return i
            });
            setExtraItemData(updatedItems)
        } else {
            setExtraItemData((prevItems => [...prevItems, { ...item, quantity: 1 }]))
        }
    };

    const minusItem = (item) => {
        const existingItem = extraItemData.find(i => i.id === item.id)
        if (existingItem) {
            const updatedItems = extraItemData.map(i => {
                if (i.id === item.id && i.quantity > 0) {
                    return { ...i, quantity: i.quantity - 1 }
                }
                return i
            }).filter(item => item.quantity > 0) // Remove items with quantity 0
            setExtraItemData(updatedItems)
        } else {
            setExtraItemData(prevItems => [...prevItems, { ...item, quantity: 0 }].filter(item => item.quantity > 0))
        }
    };

    const getCurrentQuantityById = (itemId) => {
        const currentItem = extraItemData?.find(item => item.id === itemId);
        return currentItem ? currentItem.quantity : 0;
    };

    const back = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
        const keyToRemove = steps[activeStep]
        delete selected[keyToRemove]
    }

    const next = (selectedObj) => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
        setSelected({...selected, ...selectedObj})
    }

    const selectedToReserve = () => {
        return {
            category: selected?.category?.id,
            service: selected?.service?.id,
            price: selected?.price?.id,
            date: selected.date + '=='
        }
    }

    const reserve = () => {
        const reservationData = JSON.stringify({...selectedToReserve(), ...personalData, ...billingData, extra_items: extraItemData, answers: questionData, ...paymentData, ...otherData})
        postData('reserve_authenticated/', reservationData)
            .then(r => {
                setNewReservationOpen(false)
                setReservations([...reservations, {...r, status: 'accepted'}])
            })
            .catch(error => {
                setNewReservationOpen(false)
                snackbar.enqueueSnackbar('Váratlan hiba történt :(', {variant: 'error'})
            })
    }

    const contextData = {
        activeStep,
        back,
        next,
        steps,
        personalData,
        setPersonalData,
        billingData,
        setBillingData,
        paymentData,
        setPaymentData,
        questionData,
        setQuestionData,
        otherData,
        setOtherData,
        extraItemData,
        selected,
        reserve,
        loading,
        canProceed,
        error,
        setError,
        questions,
        extraItems,
        billingDetailsMandatory,
        plusItem,
        minusItem,
        getCurrentQuantityById,
    }

    return <ReservationContext.Provider value={contextData}>
        {children}
        <Loading isLoading={loading}/>
    </ReservationContext.Provider>
}