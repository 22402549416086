import {useContext} from "react";
import BillingContext from "../../../context/BillingContext";
import BillingStepper from "../../../components/BillingStepper";
import BillingoDetails from "../billingo/BillingoDetails";
import SzamlazzDetails from "../szamlazz/SzamlazzDetails";
import BillingServiceSelect from "./BillingServiceSelect";
import Button from "@mui/material/Button";
import {Box} from "@mui/material";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";


const BillingBase = () => {
    const {activeStep, selected, formRef, addBillingService} = useContext(BillingContext)

    const billingServiceMap = {
        billingo: <BillingoDetails reference={formRef}/>,
        szamlazz: <SzamlazzDetails reference={formRef}/>
    }

    const stepMap = [
        <BillingServiceSelect/>,
        billingServiceMap[selected?.billing_service]
    ]

    const handleSave = () => {
        const data = formRef.current.getData()
        if (data) {
            addBillingService(data)
        }
    }

    return <>
        <BillingStepper/>
        {stepMap[activeStep]}
        {activeStep === stepMap?.length - 1 && <Box sx={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
            <Button
                onClick={() => handleSave()}
                variant='contained'
                endIcon={<NavigateNextRoundedIcon/>}>Mentés</Button>
        </Box>}
    </>
}

export default BillingBase