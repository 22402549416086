import {useContext} from "react";
import BillingContext from "../../../context/BillingContext";
import {Button} from "@mui/material";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";

const AddBillingButton = () => {
    const {setAddNewBillingDetail} = useContext(BillingContext)
    return <Button onClick={() => setAddNewBillingDetail(true)} variant='contained' endIcon={<AddCircleRoundedIcon/>}>Új hozzáadása</Button>

}

export default AddBillingButton