import ErrorHandlingForm from "../../../components/formValidation/ErrorHandlingForm";
import {useState} from "react";
import {Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import CustomTextField from "../../../components/formValidation/CustomTextField";
import {validateRequired} from "../../../components/formValidation/validators";

const SzamlazzDetails = ({reference, obj}) => {
    const [data, setData] = useState({
        name: '' || obj?.name,
        agent_key: '' || obj?.agent_key
    });

    const [errors, setErrors] = useState({
        name: null,
        agent_key: null,
    });

    const validators = {
        name: [validateRequired],
        agent_key: [validateRequired]
    }

    return <ErrorHandlingForm reference={reference} data={data} setData={setData} errors={errors} setErrors={setErrors} validators={validators} >
        <Stack spacing={2} marginBottom={2} marginTop={2}>
            <Typography variant='caption' color='primary.main'>Név*</Typography>
            <CustomTextField
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                name='name'
                variant='standard'
            />
            <Typography variant='caption' color='primary.main'>Agent kulcs*</Typography>
            <CustomTextField
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                name='agent_key'
                variant='standard'
            />
        </Stack>
    </ErrorHandlingForm>
}

export default SzamlazzDetails;