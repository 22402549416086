import {useContext, useState} from "react";
import BillingContext from "../../../context/BillingContext";
import {Box, Button, FormControlLabel, Grid, Radio, RadioGroup} from "@mui/material";
import Typography from "@mui/material/Typography";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";

const BillingServiceSelect = () => {
    const {next, selected} = useContext(BillingContext)
    const [billingService, setBillingService] = useState(selected?.billing_service)
    const proceed = () => next({billing_service: billingService})

    return <>
        <Box className="radio-selector" sx={{marginTop: 3, marginBottom: 3}}>
            <RadioGroup
                aria-labelledby="billing-service-select"
                name="radio-buttons-group"
                value={billingService}
                onChange={(event) => setBillingService(event.target.value)}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Box sx={{padding: '32px'}} className={billingService === 'billingo' && 'active-radio'}>
                            <FormControlLabel value="billingo" control={<Radio/>}
                                              label="Billingo"/>
                            <Typography sx={{color: '#536B74'}} variant='body2'>Abban az esetben válaszd ezt az opciót,
                                ha rendelkezel api hozzáféréssel a billingo fiókodhoz.</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={{padding: '32px'}} className={billingService === 'szamlazz' && 'active-radio'}>
                            <FormControlLabel value="szamlazz" control={<Radio/>}
                                              label="Számlázz.hu"/>
                            <Typography sx={{color: '#536B74'}} variant='body2'>Abban az esetben válaszd ezt az opciót,
                                ha rendelkezel api hozzáféréssel a számlázz.hu fiókodhoz.</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </RadioGroup>
        </Box>


        <Box sx={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
            <Button
                disabled={billingService === ''}
                variant='contained'
                onClick={proceed}
                endIcon={<NavigateNextRoundedIcon/>}>Adatok megadása</Button>
        </Box>
    </>
}

export default BillingServiceSelect;